  /**
   * セレクトボックス間で要素を移動する処理
   *
   * @see  [セレクトボックス間（multiple）でのデータの移動 – jQueryサンプル集](http://jquery-master.net/blog/select_move/)
   */
  
  // 要素を移動する関数
  function moveOption(event) {
      $('#' + event.data.from + ' option:selected').each(function() {
          // 追加先の絞り込みを解除
  
          $(this).appendTo($('#' + event.data.to));
          $(this).prop('selected', false); // 選択状態の解除
      });
  }
  
  // 追加「←」ボタンのクリック時
  $('#put-button').on('click', {from: 'users', to: 'members'}, moveOption);
  
  // 除名「→」ボタンのクリック時
  $('#remove-button').on('click', {from: 'members', to: 'users'}, moveOption);
  
  // 送信時は、絞り込みで隠れているユーザを追加し、
  // `members` 側のオプションを選択状態にする
  $('#edit-group').on('submit', function(event) {
      $('#alternate-members').children().appendTo($('#members'));
      $('#members').children().prop('selected', true);
  });
  
  
  /**
   * キーワードで絞り込む処理
   *
   */
  
  // キーワードで絞り込む関数
  function search(event) {
      var selectbox  = $('#' + event.data.key);
      var searchbox  = $('#search-' + event.data.key);
      var alternates = $('#alternate-' + event.data.key);
  
      alternates.children().each(function() {
          $(this).appendTo(selectbox).prop('selected', false);
      });
  
      var keyword = searchbox.val();
      var regex   = new RegExp('.*' + keyword + '.*', 'i');
  
      if (keyword.length > 0) {
          selectbox.children().each(function() {
              if (! $(this).text().match(regex)) {
                  $(this).appendTo(alternates);
              }
          });
      }
  
      selectbox.html(selectbox.children().sort(function(a, b) {
          var dataA = $(a).data('email');
          var dataB = $(b).data('email');
  
          return (dataA == dataB) ? 0 : (dataA < dataB) ? -1 : 1;
      }));
  }
  
  // イベント登録
  $('#search-members').on('change keyup', { key: 'members' }, search);
  $('#search-users').on('change keyup', { key: 'users' }, search);    